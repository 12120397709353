$background: #333333;
$body-back: darken($background, 7%);
$divisor: #777777;
$primary: #1abc9c;
$text: invert(#333333);
$blog-text: invert(#696767);
$blog-title: invert(#333333);

$social-text: darken(#ffffff,25%);
$facebook: darken(#3b5998,25%);
$twitter: darken(#1da1f2,25%);
$github: lighten(#000000,25%);
$linkedin: darken(#0077b5,25%);
$rss: darken(#f78322,25%);

@mixin shadow {
    // box-shadow: -2px -1px 88px 0px rgba(0, 0, 0, 0.17);
}

$inverted-background: #d3d3d3;
$inverted-blog-text: invert($blog-text);
$padding: 30px;
