@import 'variablesdark';

img, .page-body .content-page .post-thumbnail{
  opacity: 0.75;
  transition: opacity 0.5s ease-in-out;
}
img:hover, .page-body .content-page .post-thumbnail:hover {
  opacity: 1;
}

@import 'main';

a.toggleTheme {
  &:after {
    font-size: 14px;
    left: 3px;
    content: '\f185';
  }
}
